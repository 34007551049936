import React, { Component } from "react";
import { Projects } from "./Project";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export class BoxSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            projectId: "",
            textSearch: "",
            startDateShow: "",
            endDateShow: "",
            startDate: "",
            endDate: "",
            isLoading: false
        };
    }
    handleProjectChange = e => {
        this.setState({ projectId: e.target.value });
    };

    handleTextChange = e => {
        this.setState({ textSearch: e.target.value });
    };

    handleStartChange = e => {
        this.setState({ startDateShow: e });
        this.setState({ startDate: moment(e).format("YYYY-MM-DD") });
    };

    handleEndChange = e => {
        this.setState({ endDateShow: e });
        this.setState({ endDate: moment(e).format("YYYY-MM-DD") });
    };

    handleSearch = async () => {
        console.log(this.props);
        this.setState({ isLoading: true });
        var url = `api/search?p=${this.state.projectId}&t=${this.state.textSearch
            }&s=${this.state.startDate}&e=${this.state.endDate}`;
        const response = await fetch(url);
        const data = await response.json();
        this.props.setState({ logs: data });
        this.setState({ isLoading: false });
    };

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="pb-0 card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <Projects onChange={this.handleProjectChange} />
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                onChange={this.handleTextChange}
                                                className="form-control"
                                                placeholder="Tìm kiếm tiêu đề, nội dung..."
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-custom"
                                                onClick={this.handleSearch}
                                            >
                                                {" "}
                        Tìm kiếm{" "}
                                                {this.state.isLoading &&
                                                    <i className="fa fa-refresh fa-spin" />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group row">
                                        <div className="col-sm-3 row">
                                            <div className="col-sm-6">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.startDateShow}
                                                    placeholderText="Select Date"
                                                    showPopperArrow={false}
                                                    onChange={this.handleStartChange}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.endDateShow}
                                                    placeholderText="Select Date"
                                                    showPopperArrow={false}
                                                    onChange={this.handleEndChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
